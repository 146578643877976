import { render, staticRenderFns } from "./CrcManageList.vue?vue&type=template&id=68928373&scoped=true&"
import script from "./CrcManageList.js?vue&type=script&lang=js&"
export * from "./CrcManageList.js?vue&type=script&lang=js&"
import style0 from "./CrcManageList.less?vue&type=style&index=0&id=68928373&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68928373",
  null
  
)

export default component.exports