
export default {
	name: "CraDepartment",
	data() {
		return {
			httpHeader: {
				token: this.getCookie("token")
			},
			uploadFile: '',
			gUserId: "",
			tableList: [],
			departmentName: '',
			departmentId: '',
			searchMechanism: '',
			fileList: [],
			centerDialogVisible: false,
			isAddMechanism: false,
			addDepartmentName: '',
			uplaodExcelUrl: '',
			isComplete: true,
			crcName: '',
			crcPhone: '',
			id: '',
			instId: '',
			changName: '',
			changPhone: '',
			isRrcPhone: false,
			data: {},
			page: 1,
		};
	},

	created() {
		this.token = this.getCookie("token");
		this.gUserId = this.$route.params.id;
		this.uplaodExcelUrl = this.craUrl + "/uploadFile"
		if (this.token && this.gUserId) {
			this.getDepartmentList();
		}
	},

	methods: {
		getDepartmentList() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/selectCRCByHostoryIdA", {
					hostoryId: _this.gUserId,
					name: '',
					page: _this.page,
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.tableList = res.data.data.records;
						_this.data = res.data.data
					} else if (res.data.code == 401) {
						_this.$router.push("/");
					}
				});
		},
		// 新增机构
		importMechanism() {
			this.centerDialogVisible = true;
		},

		handleExceed(files, fileList) {
			this.$message.warning(`只能上传 1 个文件`);
		},
		// 新增科室
		addMechanism(index) {
			var _this = this
			if (_this.tableList[index]) {
				_this.crcName = _this.tableList[index].name
				_this.crcPhone = _this.tableList[index].phone
				_this.id = _this.tableList[index].id
				_this.instId = _this.tableList[index].instId
				_this.changName = _this.tableList[index].name
				_this.changPhone = _this.tableList[index].phone
			} else {
				_this.crcName = ''
				_this.crcPhone = ''
				_this.id = ''
			}
			_this.isAddMechanism = true
			setTimeout(function () {
				var oInput = document.getElementById("crcName");
				oInput.focus()
			}, 200)
		},
		// 删除
		deleteDepartment(index) {
			var _this = this;
			if (!_this.tableList[index].researcherNames) {
				this.$confirm("此操作将永久删除该机构, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						_this.$http.post(_this.craUrl + '/delCRCById', {
							crcId: _this.tableList[index].id
						}, {
							headers: {
								"token": _this.token
							}
						}).then(function (res) {
							if (res.data.code == 200) {
								_this.getDepartmentList();
								_this.$message({
									type: "success",
									message: "删除成功!"
								});
							}
						})

					})
					.catch(() => {

					});
			} else {
				_this.$message({
					type: "warning",
					message: "不可删除!"
				});
			}

		},

		// 保存
		saveDepartmentInfo() {
			var _this = this
			if (_this.changName == _this.crcName && _this.changPhone == _this.crcPhone) {
				_this.isAddMechanism = false
				return
			}
			if (!_this.crcName) {
				_this.$message({
					message: "请填写crc姓名!",
					type: "warning"
				});
			} else if (!_this.crcPhone) {
				_this.$message({
					message: "请填写crc手机号!",
					type: "warning"
				});
			} else if (_this.isRrcPhone) {
				_this.$message({
					message: "crc手机号重复!",
					type: "warning"
				});
			} else {
				_this.isAddMechanism = false
				_this.$http.post(_this.craUrl + '/addOrUpdateCRC', {
					id: _this.id,
					name: _this.crcName,
					instId: _this.gUserId,
					phone: _this.crcPhone
				}, {
					headers: {
						"token": _this.token
					}
				}).then(function (res) {
					if (res.data.code == 200) {
						_this.getDepartmentList();
						_this.$message({
							type: "success",
							message: "操作成功!"
						});
						_this.crcName = ''
						_this.crcPhone = ''
					}
				})
			}

		},
		handleAvatarSuccess(res, file) {
			this.uploadFile = res.data.name;
		},
		// 删除上传文件
		handleRemove(file, fileList) {
			this.uploadFile = ''
		},
		// 保存导入的内容
		saveUplaodExcelUrl() {
			var _this = this
			if (_this.uploadFile) {

			}
			_this.$http
				.post(
					_this.craUrl + "/importExcelCRC", {
					histipalId: _this.gUserId,
					fileUrl: _this.uploadFile
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.isAddMechanism = false
						_this.getDepartmentList()
						_this.centerDialogVisible = false
					}
				});
		},
		// 检查手机号
		getChangPhone(e) {
			var _this = this
			if (_this.crcPhone == _this.changPhone) {
				return
			}
			_this.$http.post(_this.craUrl + '/crcPhoneIsRepeat', {
				phone: _this.crcPhone
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					_this.isRrcPhone = false
				} else if (res.data.code == 217) {
					_this.isRrcPhone = true
					_this.$message({
						type: "warning",
						message: "手机号重复!"
					});
				}
			})
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getDepartmentList()
		}
	},
};
